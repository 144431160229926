import React, { useEffect, useRef, useState } from "react";
import ChatGroupCard from "./ChatGroupCard";

import style from "../../css/chat.module.css";
import {
  addUpdateRoom,
  checkRoomChat,
  createChatRoomData,
  createMessage,
  createMsgData,
  getMessageList,
  getRoomChatList,
  readMessage,
  uploadMsgImage,
} from "../../controller/chatController";
import { globalStorage } from "../../utils/Storage";
import { deleteAlert, errorAlert } from "../../controller/globalController";
import ChatHeader from "./ChatHeader";
import ChatBubble from "./ChatBubble";

import firestore from "../../utils/Firebase";
import { collection, doc, limit, orderBy, query, setDoc, where } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { useLocation } from "react-router-dom";
import { CDropdown } from "../CustomComponent/StyledComponent";

const statusList = [
  { id: 0, name: 'Semua' },
  { id: 1, name: 'Belum dibaca' },
  { id: 2, name: 'Belum dijawab' }
]

const init_state = {
  roomList: [],
  msgList: [],
  selected_room: undefined,
  msgText: "",
  statFilter: statusList[0],

  // room
  roomPage: 1,
  lastRoomPage: 1,
  hasMoreRoom: false,
  roomLoading: false,

  // chat
  msgPage: 1,
  lastMsgPage: 1,
  hasMoreMsg: false,
  msgLoading: false,

  send_loading: false,
  action_toggle: false,
};

function ChatMain({ type = "buyer", ...props }) {
  const loc_state = useLocation().state;
  const s_flag = type === "seller";
  const userData = globalStorage.getItem("UD");
  const storeData = globalStorage.getItem("SD");
  const usr_id = s_flag ? userData?.seller?.id : userData?.id;

  var start_flag = loc_state?.start_chat ?? false;
  var cusData = loc_state?.cusData ?? null;
  var product = loc_state?._product ?? "";
  window.history.replaceState({}, document.title);

  const abortCtrl = new AbortController();
  const refBottomChat = useRef();
  const refChatText = useRef();
  const refSearchText = useRef();

  const [state, setState] = useState(init_state);
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const selectRoom = async (selected_room) => {
    delete selected_room["message"];
    changeState({ selected_room });
  };
  const handleActionToggle = (action_toggle) => changeState({ action_toggle });

  // firebase
  const room_collection = collection(firestore, "RoomChats");
  const [snapshotRoom, loadingRoom, errorRoom] = useCollection(
    query(
      room_collection,
      where(`${s_flag ? "seller" : "user"}_id`, "==", usr_id),
      limit(10),
      orderBy("updated_at", "desc")
    ),
    { snapshotRoomListenOptions: { includeMetadataChanges: true } }
  );

  const chatCollection = `RoomChats/${state.selected_room?.room_id}/Chat`;
  const [snapshotMsg, laodingMsg, errorMsg] = useCollection(
    collection(firestore, chatCollection),
    { snapshotRoomListenOptions: { includeMetadataChanges: true } }
  );
  // //////////////////////////////

  const loadRoomList = async (page = 1, limit = 10, search = "", status = state.statFilter) => {
    const rm_data = { seller_id: null, user_id: null, cancel: abortCtrl };
    if (search !== "") rm_data.search = search;
    if (s_flag) rm_data.seller_id = userData.seller.id;
    else rm_data.user_id = userData.id;
    const roomList = await getRoomChatList({ ...rm_data, page, limit, status });
    if (roomList === undefined) return undefined;
    return {
      roomList: roomList.data,
      roomPage: roomList.current_page,
      lastRoomPage: roomList.current_page,
      hasMoreRoom: roomList.current_page < roomList.current_page,
      roomLoading: false,
    };
  };
  const loadMsgList = async (page = 1, limit = 10, room = null) => {
    if (room === null) return {};
    const chat_data = { room_id: room.room_id, cancel: abortCtrl, page, limit };
    const msgList = await getMessageList(chat_data);
    if (msgList === undefined) return undefined;
    return {
      msgList: msgList.data.reverse(),
      msgPage: msgList.current_page,
      lastMsgPage: msgList.current_page,
      hasMoreMsg: msgList.current_page < msgList.current_page,
      msgLoading: false,
    };
  };
  const sendImage = async (event) => {
    event.preventDefault();
    const img = event.target.files[0];
    const flag = img.size > 16000000;
    const seller_id = state.selected_room.seller_id;
    if (flag) return errorAlert("Maksimal ukuran file gambar adalah 16Mb.");
    // changeState({ send_loading: true });
    const message_item = await uploadMsgImage([img], seller_id);
    doSendMessage("", message_item);
  };

  const sendMessage = async (event) => {
    event.preventDefault();
    changeState({ send_loading: true });
    const text = refChatText.current.value;
    doSendMessage(text);
  };

  const doSendMessage = async (text = "", message_item = "") => {
    changeState({ send_loading: true });
    const sender = s_flag ? userData.seller : userData;
    const room = state.selected_room;
    room.last_message = text;
    room[`unread_message_${!s_flag ? "seller" : "buyer"}`]++;

    const img_flag = message_item !== "";
    const msg = createMsgData({ sender, room: state.selected_room, type });
    msg.message_type = img_flag ? "image" : "text";
    msg.message_text = text;
    msg.message_status = "delivered";
    // msg.product_data = product ?? "";
    msg.product_data = "";
    msg.message_item = message_item;

    const res_msg = await createMessage(msg);
    if (res_msg === undefined) return errorAlert("Gagal mengirim pesan");
    const roomDoc = doc(firestore, "RoomChats", room.room_id);
    await setDoc(roomDoc, room);
    await setDoc(doc(roomDoc, "Chat", msg.id), msg);

    // clear msg input /////////////////////////////////
    product = "";
    start_flag = false;
    refChatText.current.value = "";
    refBottomChat?.current?.scrollIntoView({ behavior: "smooth" });
    changeState({ send_loading: false });
  };

  const readAllMessage = async (room_id, flag = s_flag, init = false) => {
    let data = { action_toggle: false };
    const sender_type = flag ? 1 : 0;
    const res = await readMessage({ room_id, sender_id: usr_id, sender_type });
    if (res === undefined) return false;
    const room = state.roomList.find((r) => r.room_id === room_id);
    if (room) room[`unread_message_${flag ? "seller" : "buyer"}`] = 0;
    readAllFirebase(room);
    if (init) data = (await loadMsgList(1, 10, room)) ?? {};
    return data;
  };
  const readAllFirebase = async (room) => {
    delete room.message;
    const roomDoc = doc(firestore, "RoomChats", room.room_id);
    await setDoc(roomDoc, room);
    snapshotMsg.docs.map(async (msg) => {
      const { message_status, sender_id, id } = msg.data();
      if (message_status !== "read" && sender_id === usr_id) {
        await setDoc(doc(roomDoc, "Chat", id), {
          ...msg.data(),
          message_status: "read",
        });
      }
    });
  };
  const hideRoom = async (room_id) => {
    const text = "Apakah kamu yakin untuk menghapus pesan ini?";
    const conf = await deleteAlert(text);
    if (!conf.value) return;

    const tempList = state.roomList.filter((r) => r.room_id !== room_id);
    const room = { room_id };
    room[`room_hidden_${s_flag ? "seller" : "buyer"}`] = 1;
    const res = await addUpdateRoom(room);
    if (res === undefined) return errorAlert("Gagal Menghapus Chat");
    const roomData = { roomList: tempList, selected_room: undefined };
    changeState({ action_toggle: false, ...roomData, msgList: [] });
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    const search = refSearchText.current.value;
    const status = state.statFilter.id
    changeState({ loadingRoom: true });
    const roomListData = (await loadRoomList(null, null, search, status)) ?? {};
    changeState({ ...roomListData });
  };

  const handleStatusFilter = async (statFilter) => {
    changeState({ loadingRoom: true, statFilter });
    const search = refSearchText.current.value;
    const status = statFilter.id;
    const roomListData = (await loadRoomList(null, null, search, status)) ?? {};
    changeState({ ...roomListData });
  }

  const initPage = async () => {
    changeState({ roomLoading: true });
    let selected_room = undefined;
    if (!s_flag && start_flag) {
      const room_data = [storeData?.id, userData?.id, abortCtrl];
      selected_room = await checkRoomChat(...room_data);
      let room = { room_id: selected_room, room_hidden_buyer: 0 };
      if ([null, undefined].includes(selected_room)) {
        room = createChatRoomData(storeData, userData);
      }
      const res = await addUpdateRoom(room);
      const err_text = "Gagal membuat ruang obrolan";
      if (res === undefined) return errorAlert(err_text);
      selected_room = res;
      delete selected_room.message;
    }

    if (s_flag && start_flag && cusData) {
      const room_data = [storeData?.id, cusData.id, abortCtrl];
      selected_room = await checkRoomChat(...room_data);
      let room = { room_id: selected_room, room_hidden_buyer: 0 };
      if ([null, undefined].includes(selected_room)) {
        room = createChatRoomData(storeData, { id: cusData.id, name: cusData.first_name, logo: null });
      }
      const res = await addUpdateRoom(room);
      const err_text = "Gagal membuat ruang obrolan";
      if (res === undefined) return errorAlert(err_text);
      selected_room = res;
      delete selected_room.message;
    }

    const roomListData = (await loadRoomList()) ?? {};
    changeState({ ...roomListData, selected_room });
  };

  useEffect(() => {
    if (userData && storeData) initPage();
    return () => abortCtrl.abort();
  }, [type]);

  useEffect(() => {
    const setMsg = async () => {
      const room = state.selected_room;
      const data = await readAllMessage(room.room_id, undefined, true);
      changeState({ ...data });
      refChatText?.current?.focus();
      refBottomChat?.current?.scrollIntoView({ behavior: "smooth" });
    };

    const noRoomFlag = [undefined, null].includes(state.selected_room);
    if (noRoomFlag || userData === undefined || storeData === undefined) return;
    if (userData && storeData) setMsg();

    return () => abortCtrl.abort();
  }, [state.selected_room]);

  useEffect(() => {
    const loadRoomData = async (room) => {
      if (room) await readAllMessage(room.room_id);
      let roomListData = (await loadRoomList()) ?? {};
      let msgListData = (await loadMsgList(1, 10, room)) ?? {};
      changeState({ ...roomListData, ...msgListData });
    };

    const room = state.selected_room ?? undefined;
    if (loadingRoom || errorRoom !== undefined) return;
    loadRoomData(room);
  }, [snapshotRoom?.docChanges()]);

  return (
    <div className={style.container}>
      <div className={style.list_container}>
        <form className={style.input_part}>
          <input ref={refSearchText} disabled={state.roomLoading} />
          <button
            type="submit"
            onClick={(e) => handleSearch(e)}
            disabled={state.roomLoading}
          >
            {state.roomLoading ? "Loading..." : "Cari"}
          </button>
        </form>
        <CDropdown bg_color="white" color="var(--main_color)" border_color='var(--main_color)'>
          <CDropdown.Toggle id="dropdown-autoclose-true" className={style.statfilterbtn}>
            {state.statFilter.name}
          </CDropdown.Toggle>
          <CDropdown.Menu style={{ width: '100%' }}>
            {statusList.map((item, index) => {
              return (
                <CDropdown.Item key={`stat_${index}`} onClick={() => handleStatusFilter(item)}>
                  {item.name}
                </CDropdown.Item>
              );
            })}
          </CDropdown.Menu>
        </CDropdown>
        <div className={`scroll_container ${style.list_body}`}>
          {state.roomLoading && <div>Loading..</div>}
          {!state.roomLoading &&
            state.roomList.map((data, idx) => {
              return (
                <ChatGroupCard
                  key={idx}
                  data={data}
                  type={type}
                  selected_room={state.selected_room}
                  onClick={selectRoom}
                />
              );
            })}
        </div>
      </div>
      <div className={style.chat_container}>
        {state.selected_room && (
          <>
            <ChatHeader
              type={type}
              toggle={state.action_toggle}
              selected_room={state.selected_room}
              changeToggle={handleActionToggle}
              hideRoom={hideRoom}
            />
            <div className={`scroll_container ${style.chat_body}`}>
              {state.msgLoading && <>Loading...</>}
              {state.msgList.map((chat, idx) => (
                <ChatBubble
                  chat={chat}
                  key={idx}
                  user_id={usr_id}
                  room={state.selected_room}
                />
              ))}
              <span ref={refBottomChat}></span>
            </div>
            <div className={style.chat_footer}>
              <form className={style.input_part}>
                <input ref={refChatText} disabled={state.send_loading} />
                <div className={style.image_btn}>
                  <label htmlFor={"image_input"}>
                    <i className="bi bi-image-fill" />
                  </label>
                  <input
                    id="image_input"
                    type="file"
                    value=""
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={(e) => sendImage(e)}
                    disabled={state.send_loading}
                  />
                </div>
                <button
                  type="submit"
                  onClick={(e) => sendMessage(e)}
                  disabled={state.send_loading}
                >
                  {state.send_loading ? "Loading..." : "Kirim"}
                </button>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ChatMain;
