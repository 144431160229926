import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import { getWishList } from "../../controller/wishlistController";
import style from "../../css/userProfile/wishlist.module.css";
import LoadMoreStore_ProductLoading from "../Loading/LoadMoreStore_ProductLoading";
import WishlistCardView from "./WishlistCardView";

function WishlistMainView(props) {
  const [state, setState] = useState({
    list: [],
    page: 1,
    last_page: undefined,
    hasMore: false,
    is_loading: false,
    is_loading_page: false,
  });
  const { list, is_loading } = state;

  // ref
  const observer = useRef();
  const lastElRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(async (entries) => {
        if (!entries[0].isIntersecting || !state.hasMore) return;
        changeState({ is_loading: true });
        const page = state.page + 1;
        const temp = await getWishList(page);
        changeState({
          page: temp.current_page,
          product_list: [...state.list, ...temp.data],
          hasMore: page < temp.last_page,
          last_page: temp.last_page,
          is_loading: false,
        });
      });
      if (node) observer.current.observe(node);
    },
    [state.hasMore, state.page]
  );

  // function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const initPage = async () => {
    changeState({ is_loading_page: true });
    const list = await getWishList();
    changeState({
      list: list?.data ?? [],
      page: list?.current_page ?? 1,
      last_page: list?.last_page ?? 1,
      hasMore: (list?.current_page < list.last_page) ?? false,
      is_loading_page: false,
    });
  };

  // useEffect
  useEffect(() => initPage(), []);

  // view
  return (
    <div className={`${style.container}`}>
      <div className={style.header}>
        <div className={style.sub_title}>
          <span>{list.length}</span>
          <span>barang</span>
        </div>
      </div>
      {state.is_loading_page && (
        <div className={style.list_inner_container}>
          <LoadMoreStore_ProductLoading count={5} />
        </div>
      )}
      {!state.is_loading_page && (
        <>
          {!is_loading && list.length === 0 && <div>Kosong</div>}
          <div className={style.list_container}>
            <div className={style.list_inner_container}>
              {list.map((data, idx) => {
                const key = `${data.product_id}_${idx}`;
                const lastRef = idx + 1 === list.length ? lastElRef : null;
                return (
                  <WishlistCardView key={key} data={data} lastElRef={lastRef} />
                );
              })}
            </div>
            {is_loading && <LoadMoreStore_ProductLoading count={1} />}
          </div>
        </>
      )}
    </div>
  );
}

export default WishlistMainView;
